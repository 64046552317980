import { defineStore } from 'pinia';

export const userStore = defineStore('user', {
    state: () => ({
        theme: 'test',
        darkMode: false,
        application: {},
        selectedApplications: [],
        applications: [],
    }),
    getters: {
        getApplicationById: (state) => {
            return (applicationId) => {
                return state.applications.find((application) => application.id === applicationId)
            }
        },
    },
    actions: {
        // any amount of arguments, return a promise or not
        setTheme(theme) {
            let dark = false;

            if (theme === 'system') {
                if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    dark = true;
                }
            }
            if (theme === 'dark') {
                dark = true;
            }
            this.theme = theme;
            this.darkMode = dark;
        },
        setApplications(applications) {
            this.applications = applications;
            let applicationId = 0;
            let selectedApplications = sessionStorage.getItem("selectedApplications");
            if (selectedApplications == null) {
                selectedApplications = '0';
            }
            selectedApplications = selectedApplications.split(',');
            if (selectedApplications.length == 1) {
                applicationId = selectedApplications[0];
            }
            if (Object.keys(applications).length === 2 && (applicationId == 0 || applicationId == null)) {
                applicationId = Object.keys(applications)[Object.keys(applications).length - 1];
                this.setLookAndFeel(applications[applicationId]);
                selectedApplications = applicationId;
            }
            this.application = applications[applicationId];
            this.selectedApplications = selectedApplications;
            window.selectedApplications = selectedApplications;
        },
        setLookAndFeel(application) {
            const stringAllId = '0';
            let selectedApplications = sessionStorage.getItem("selectedApplications");
            if (selectedApplications == null) {
                selectedApplications = '0';
            }
            selectedApplications = selectedApplications.split(',');
            if (application.id != 0) {
                const stringId = application.id.toString();
                if (!selectedApplications.includes(stringId)) {
                    //add to array
                    selectedApplications.push(stringId);
                } else {
                    //remove from array
                    var index = selectedApplications.indexOf(stringId);
                    if (index !== -1) {
                        selectedApplications.splice(index, 1);
                    }
                }
                if (selectedApplications.includes(stringAllId)) {
                    //remove 'all'
                    var index = selectedApplications.indexOf(stringAllId);
                    if (index !== -1) {
                        selectedApplications.splice(index, 1);
                    }
                }
                selectedApplications = selectedApplications.length > 0 ? selectedApplications.join(',') : stringAllId;
            } else {
                //reset 'all'
                selectedApplications = stringAllId;
            }
            sessionStorage.setItem("selectedApplications", selectedApplications);
            this.application = selectedApplications.length == 1 ? this.applications[selectedApplications[0]] : this.applications[0];
            this.selectedApplications = selectedApplications.split(',');
        },
    },
})
