<template>
  <div :class="[width ? `w-${width}` : '', classes ? classes : '']"
       class="relative flex select"
       @blur="blur"
       @keydown.down.stop="onDownFocus"
       @keydown.up.stop="onUpFocus"
       @keydown.enter.stop="onEnter"
       @keydown.tab.stop="onTab"
       :tabindex="tabindex"
  >
    <beekman-dropdown ref="dropdown" :close-on-click="true" :disabled="disabled || (searchUrl == '' && filtered.length < 1)" :force-close="forceClose"
                      :force-open="forceOpen" :is-select="true" :width="width" @closed="blur" @opened="opened"
                      :with-empty-row="!searchUrl">
      <template #trigger="{ open, uuid }">
        <button :class="{novalue:value === ''}" :readonly="disabled || (searchUrl == '' && filtered.length < 1)"
                class="selected bg-white"
                :ref="`trigger-button`"
                type="button">
          <div :title="label" class="flex justify-between w-full items-center ellipsis"
               v-html="selectLabel"></div>
          <span class="absolute right-2">
            <i :class="{
              'transform rotate-180 transition duration-150':open === true,
              'transform rotate-0 transition duration-150':open === false
            }" class="far fa-angle-down"/>
          </span>
          <span v-if="value && !disabled && resetButton" class="absolute right-5" @click.stop="reset">
            <i class="fal fa-times fa-xs"/>
          </span>
        </button>
      </template>
      <template #content>
        <div v-if="filterable" class="filterable">
          <input v-model="filterTerm" :placeholder="trans('global.searchPlaceholder')" type="text" @click.stop
                 ref="field-filter"
                 @keyup.stop="filter"/>
          <i class="text-gray-600" v-if="filterTerm.length > 0 && filterTerm.length < minChars">
            Min. {{ minChars }} tekens
          </i>
          <i class="text-gray-600" v-if="filtered.length == 0 && filterTerm.length >= minChars">
            Geen resultaat
          </i>
        </div>
        <div v-if="searchUrl" class="filterable">
          <input v-model="searchTerm" :placeholder="trans('global.searchPlaceholder')" type="text" @click.stop
                 ref="field-search"
                 @keyup.stop="searching"/>
          <i class="text-gray-600" v-if="searchTerm.length > 0 && searchTerm.length < minChars">
            Min. {{ minChars }} tekens
          </i>
          <i class="text-gray-600" v-if="filtered.length == 0 && searchTerm.length >= minChars">
            <template v-if="isSearching">
              <i class="fal fa-spinner fa-spin"></i>
            </template>
            <template v-else>
              Geen resultaat
            </template>
          </i>
        </div>
        <button v-if="resetButton && !searchUrl && filterTerm == ''" :ref="`row-0`"
                :class="[
                        null == selectedValue ? 'selected' : '',
                        0 == currentOption ? 'active' : '',
                      ]"
                class="option"
                @click="selected(0)">
          <div class="content" v-html="'&nbsp;'"></div>
        </button>
        <template v-for="(option,index) in filtered">
          <div class="group-title" v-if="option.groupTitle && (filtered[index + 1] && !filtered[index + 1].groupTitle)">{{option.groupTitle}}</div>
          <button v-if="option.value" :ref="`row-${index+1}`"
                  :class="[
                          (option.value == selectedValue && value == selectedValue) || option.value == value ? 'selected' : '',
                          index+1 == currentOption ? 'active' : '',
                        ]"
                  class="option"
                  @click="selected(option.value)">
            <div class="content" v-html="option.title"></div>
          </button>
        </template>
      </template>
    </beekman-dropdown>
  </div>
</template>

<script>
import Select from "@ui/Mixins/Select.js";
import BeekmanFocusNextField from "@crud/Mixins/Form/FocusNextField.js";

export default {
  mixins: [
    Select,
    BeekmanFocusNextField,
  ],
  data() {
    return {
      selectLabel: this.resetData(),
      forceOpen: false,
      forceClose: false,
      currentOption: this.getCurrentOption(),
    };
  },
  mounted() {
    this.filtered = this.options;
  },
  watch: {
    selectedValue(value) {
      this.selected(value);
    },
    options(value) {
      if (!this.searchUrl) {
        this.resetData();
        this.filtered = value;
      }
    },
    resetSelect(newValue, oldValue) {
      if (newValue) {
        this.reset();
      }
    }
  },
  methods: {
    opened() {
      if (this.$refs[`field-search`]) {
        setTimeout(() => {
          this.$nextTick(() => this.$refs[`field-search`].focus());
        }, 1);
      }
      if (this.$refs[`field-filter`]) {
        setTimeout(() => {
          this.$nextTick(() => this.$refs[`field-filter`].focus());
        },1);
      }
      if ((typeof this.$refs[`field-search`] == 'undefined' && typeof this.$refs[`field-filter`] == 'undefined' ) && typeof this.$refs[`trigger-button`] != 'undefined') {
        setTimeout(() => {
          this.$nextTick(() => this.$refs[`trigger-button`].focus());
        },1);
      }
      setTimeout(() => {
        this.fixScrolling();
      },1);
    },
    selected(value) {
      this.value = value;
      this.setSelectedLabel(value);
      this.$emit('selected', value);
    },
    clear() {
      this.selectLabel = this.label;
      this.value = '';
    },
    onDownFocus(event) {
      if (this.disabled) {
        return false;
      }
      this.forceOpen = true;
      this.onDown(event);
    },
    onUpFocus(event) {
      if (this.disabled) {
        return false;
      }
      this.forceOpen = true;
      this.onUp(event);
    },
    reset() {
      if (this.disabled) {
        return;
      }
      this.selectLabel = this.label;
      this.value = '';
      this.$emit('selected', this.value);
    },
    setSelectedLabel(value) {
      for (let option of Object.values(this.filtered)) {
        if (option.value == value) {
          this.selectLabel = option.title;
        }
      }
      // no valid value rest to title and set empty value
      if (value === 0 || value === '' || value === null) {
        this.clear();
      }
    },
    resetData() {
      let label = this.label;
      for (let option of Object.values(this.options)) {
        if (option.icon) {
          this.options[Object.values(this.options).indexOf(option)].title = `<div><i class="mr-2 ${option.icon}"></i>${option.title}</div>`;
        }
      }
      if (this.selectedValue !== null && this.selectedValue !== '') {
        if (this.options.length) {
          for (let option of Object.values(this.options)) {
            if (option.value == this.selectedValue) {
              label = option.title;
            }
          }
        }
      }
      return label;
    },
    getCurrentOption() {
      let currentOption = this.resetButton && !this.searchUrl ? 0 : 1;
      if (this.selectedValue) {
        if (this.options.length) {
          for (let [index, option] of Object.entries(this.options)) {
            if (option.value == this.selectedValue) {
              currentOption = parseInt(index) + 1;
            }
          }
        }
      }
      return currentOption;
    },
    onEnter(event) {
      event.preventDefault();
      if (!this.filtered.length) {
        return;
      }
      this.selected(this.currentOption == 0 ? null : this.filtered[this.currentOption - 1].value);
      this.forceOpen = false;
      this.forceClose = true;
      this.$refs.dropdown.closeDropdown(true);
      this.focusNextField();
    },
    onTab(event) {
      event.preventDefault();
      this.forceOpen = false;
      this.forceClose = true;
      this.$refs.dropdown.closeDropdown(true);
      this.focusNextField();
    },
  },
}
</script>
