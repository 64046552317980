<template>
  <div :id="'dropdown-' + uid" ref="dropdown" :class="[styling]" class="relative dropdown h-full">
    <div class="h-full dropdown-button" @click="!disabled ? open = ! open : false" :class="(visible ? 'active' : '')">
      <slot name="trigger" v-bind:open="open" v-bind:uuid="uid"></slot>
    </div>
    <div v-show="visible"
         :class="[widthClass, alignmentClasses, topClasses, dropdownClasses]"
         class="dropdown-content z-50"
         style="display: none;"
         @click="closeOnClick || forceClose ? open = false : false">
      <div ref="dropdownList" :class="[classes, dropdownListClasses]" :style="{'width': `${dropdownWidth}px`}">
        <slot :style="{'width': `${dropdownWidth}px`}" name="content" v-bind:uuid="uid"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['opened', 'closed'],
  props: {
    align: {
      default: 'left'
    },
    width: {
      default: 'full'
    },
    top: {
      default: ''
    },
    contentClasses: {
      default: () => ['bg-gray-300 max-h-48 overflow-y-auto']
    },
    dropdownClasses: {
      default: () => ['absolute']
    },
    dropdownListClasses: {
      default: () => ['shadow-2xl shadow-dark-500/100 rounded-b']
    },
    styling: {
      default: () => ['w-full h-full']
    },
    useOverlay: {
      default: true
    },
    closeOnClick: {
      default: false
    },
    forceClose: {
      default: false
    },
    forceOpen: {
      default: false
    },
    disabled: {
      default: false
    },
    isSelect: {
      default: false
    },
  },
  data() {
    return {
      open: this.forceOpen,
      dropdownWidth: '',
      classes: '',
      uid: this.uuid(),
    }
  },
  mounted() {
    this.setDropdownWidth();
    this.setClasses();
  },
  beforeMount() {
    window.addEventListener('keydown', this.closeOnEscape, null);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.closeOnEscape);
    window.document.removeEventListener('click', this.checkClickOutside);
  },
  methods: {
    setDropdownWidth() {
      if (this.isSelect) {
        this.dropdownWidth = this.$refs.dropdown.clientWidth;
      }
    },
    closeOnEscape($event) {
      if (this.open && $event.keyCode === 27) {
        this.open = false
      }
    },
    checkClickOutside(event) {
      if (!event.target.closest('#dropdown-' + this.uid)) {
        this.open = false;
      }
    },
    closeDropdown(value) {
      if (value === true) {
        this.open = false;
      }
    },
    uuid() {
      let uuid = "", i, random;
      for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;

        if (i == 8 || i == 12 || i == 16 || i == 20) {
          uuid += "-"
        }
        uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
      }
      return uuid;
    },
    setClasses() {
      let classes = this.contentClasses;
      if (this.isSelect) {
        var bounding = this.$refs.dropdown.getBoundingClientRect();
        //if visible in viewport
        if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= window.innerWidth && bounding.bottom <= window.innerHeight) {
          classes += ' fixed';
        } else {
          classes += ' absolute';
        }
      }
      this.classes = classes;
    }
  },
  watch: {
    forceClose(newValue) {
      if (newValue === true) {
        this.open = false;
      }
    },
    forceOpen(newValue) {
      if (newValue === true) {
        this.open = true;
      }
    }
  },
  computed: {
    visible() {
      if (this.open) {
        if(this.dropdownWidth == 0) {
          this.setDropdownWidth();
        }
        this.$emit('opened');
        window.document.addEventListener('click', this.checkClickOutside);
      } else {
        this.$emit('closed');
        window.document.removeEventListener('click', this.checkClickOutside);
      }
      return this.open === true;
    },
    widthClass() {
      return `w-${this.width}`;
    },
    alignmentClasses() {
      if (this.align === 'left') {
        return 'origin-top-left left-0'
      } else if (this.align === 'right') {
        return 'origin-top-right right-0'
      } else {
        return 'origin-top'
      }
    },
    topClasses() {
      if (this.top !== '') {
        return this.top;
      }
    }
  },
}
</script>
